// Libs.
import React from 'react';
// Deps.
import AppLayout from "../../components/layout/AppLayout";
import SEO from '../../components/layout/seo';
import FlippContainer from '../../components/flipp/flipp-container';

const FlyerPage = () => {
  return (
    <AppLayout>
      <SEO title="Flyer" />
      <FlippContainer />
    </AppLayout>
  );
};
export default FlyerPage;